@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap');

$main-color: #3C4B64;
// $main-color: #14b0e5;
$main-color-dark: #303C54;
$light-color: #F1F1F1;
$pallet1: #dff3fa;
$pallet2: #7cdeff;
$pallet3: #00bfff;
$pallet4: #073f52;
$white-color: white;
$black-color: rgb(44, 44, 44);
$dark-back: rgb(27, 27, 27);
$dark-back-light: rgb(39, 39, 39);

// dashboard colors 
$drawer-color: #3C4B64;
$drawer-color-dark: #303C54;
// dashboard colors 

// Mixin 
@mixin transitions{
    font-family: 'Montserrat', sans-serif;
    -webkit-transition: all 0.5s ease-out;
    -moz-transition: all 0.5s ease-out;
    -o-transition: all 0.5s ease-out;
    -webkit-transition: all 0.5s ease-in;
    -moz-transition: all 0.5s ease-in;
    -o-transition: all 0.5s ease-in;
}
@mixin scrollAutoHidden{
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
}

body{
    font-family: 'Montserrat', sans-serif;
    background-color: $light-color;
    overflow: hidden;
    input, select, textarea, button:focus{
        outline: 0;
        outline: none;
    }
    .mainSectionHeading{
        font-weight: 600;
        font-size: 30px;
        color: $main-color;
    }
    .subSectionHeading{
        font-weight: 600;
        font-size: 45px;
    }
    .separator{
        height: 2px;
        padding: 0 !important;
        background-color: $main-color;
        margin-top: 20px;
        margin-bottom: 20px;
    }
    .applyBut{
        background-color: $main-color;
        color: $white-color;
        border: 2px solid $main-color;
        padding: 5px 20px;
        font-size: larger;
        @include transitions;
        border-radius: 2px;
        margin: 0 5px;
        margin-top: 10px;
        &:hover{
            transform: scale(1.05);
            background-color: $white-color;
            color: $main-color;
        }
    }

    .mainButLight{
        background-color: $white-color;
        border: 1px solid $white-color;
        font-size: larger;
        font-weight: 600;
        color: $main-color;
        border-radius: 2px;
        padding: 5px 10px;
        &:hover{
            background-color: $main-color;
            color: $white-color;
        }
    }
    .mainButDark{
        background-color: $main-color;
        border: 1px solid $main-color;
        font-size: larger;
        font-weight: 600;
        color: $white-color;
        border-radius: 3px;
        padding: 5px 10px;
        &:hover{
            background-color: rgba(0, 0, 0, 0);
            color: $main-color;
        }
    }
    .mainButDarker{
        background-color: $dark-back;
        border: 1px solid $dark-back;
        font-size: larger;
        font-weight: 600;
        color: $white-color;
        border-radius: 3px;
        padding: 5px 10px;
        @include transitions;
        &:hover{
            transform: scale(1.1);
            background-color: $dark-back-light;
        }
    }

    .copyBut{
        background-color: $light-color;
        color: $black-color;
        border: 2px solid $light-color;
        padding: 5px 20px;
        font-size: larger;
        @include transitions;
        border-radius: 2px;
        margin: 0 5px;
        margin-top: 10px;
        &:hover{
            transform: scale(1.05);
            background-color: $white-color;
            box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
        }
    }

    .navLogoutBut{
        background-color: #fe2f2f;
        color: white;
        font-size: larger;
        font-weight: 600;
        border: 0;
        border-radius: 5px;
        padding: 10px 20px;
        // width: 100%;
        i{
            margin-right: 10px;
        }
        &:hover{
            background-color: red;
        }
    }

    .loader{
        background-color: RGB(42, 88, 244);
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100vh;
        text-align: center;
        h2{
            color: white;
            font-weight: 700;
            text-transform: capitalize;
        }
        img{
            max-width: 100%;
        }
    }
    
    // login 
    .login{
        background-color: $light-color;
        color: black;
        .container-fluid{
            .row{
                .loginLeft{
                    padding: 0 !important;
                    background-size: auto 100%;
                    min-height: 100vh;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    text-align: center;
                    color: white;
                    h1{
                        font-weight: 900;
                    }
                    h2{
                        font-weight: 800;
                    }
                    .stats{
                        background-color: rgba(27, 27, 27, 0) !important;
                        border: 0 !important;
                    }
                    .singleStat{
                        background-color: rgba(27, 27, 27, 0.5) !important;
                        padding-top: 20px;
                        padding-bottom: 20px;
                    }
                }
                .loginRight{
                    text-align: center;
                    img{
                        height: 200px;
                        margin-bottom: 30px;
                    }
                    .form{
                        text-align: left;
                        width: 70%;
                        margin: 0 auto;
                        margin-top: 50px;   
                        input, select{
                            width: 100%;
                            padding: 5px 10px;
                            margin-bottom: 20px;
                        }
                        .passwordCol{
                            position: relative;
                            span{
                                position: absolute;
                                right: 10px;
                                top: 2px;
                            }
                        }
                        a{
                            text-decoration: none;
                            color: $main-color;
                            &:hover{
                                color: $main-color-dark;
                            }
                        }
                        button{
                            width: 100%;
                            padding: 10px 20px;
                            margin-bottom: 10px;
                            border: 0;
                            background-color: $main-color;
                            color: white;
                            font-size: larger;
                            font-weight: 600;
                            @include transitions;
                            &:hover{
                                background-color: $pallet4;
                                transform: scale(1.05);
                            }
                        }
                        .createBut{
                            width: 100%;
                            padding: 10px 20px;
                            margin-bottom: 10px;
                            border: 0;
                            background-color: white;
                            border: 1px solid $main-color;
                            color: $main-color;
                            font-size: larger;
                            font-weight: 600;
                            @include transitions;
                            &:hover{
                                background-color: $main-color;
                                color: white;
                            }
                        }
                    }
                }
            }
        }
    }
    // login 

    // create blog 
    .createBlogPage{
        color: $dark-back;
    }
    .createBlog{
        padding-top: 50px;
        padding-bottom: 50px;
        .header{
            .title{
                input{
                    width: 100%;
                    padding: 5px 10px;
                    background-color: rgba(0, 0, 0, 0);
                    border: 1px solid rgb(196, 196, 196);
                }
                .desc{
                    margin-top: 20px;
                }
            }
            .imgUpload{
                input{
                    display: none;
                }
                .customImgUpload{
                    margin-top: 20px;
                    margin-bottom: 20px;
                    text-align: left;
                    .imgPreview{
                        width: 50px;
                        height: 50px;
                        position: relative;
                        @include transitions;
                        .deleteBut{
                            display: none;
                            position: absolute;
                            top: 0;
                            right: 0;
                            color: red;
                        }
                        img{
                            width: 100%;
                            height: 100%;
                        }
                        &:hover{
                            cursor: pointer;
                            .deleteBut{
                                background-color: rgba(255, 255, 255, 0.5);
                                width: 50px;
                                height: 50px;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                            }
                        }
                    }
                    button{
                        background-color: $main-color-dark;
                        border: 0;
                        color: white;
                        padding: 5px 10px;
                        border-radius: 3px;
                        &:hover{
                            background-color: $pallet4;
                        }
                    }
                    p{
                        margin-top: 5px;
                    }
                }
            }
        }
        .quillBody{
            margin-top: 20px;
            margin-bottom: 20px;
            .quill{
                .ql-editor{
                    min-height: 300px;
                }
            }
            .mainButDark{
                margin-top: 20px;
            }
        }
        .footer{
            button{
                margin-top: 20px;
            }
            .keywords{
                input{
                    width: 100%;
                    padding: 5px 10px;
                    background-color: rgba(0, 0, 0, 0);
                    border: 1px solid rgb(196, 196, 196);
                }
            }
        }
    }
    // create blog 

    // singleBlog 
    .singleBlog{
        .header{
            img{
                width: 100%;
            }
            h1{
                margin-top: 20px;
                margin-bottom: 20px;
                font-weight: 800;
                color: $main-color;
            }
        }
        .body{
            img{
                max-width: 100% !important;
            }
        }
        .author{
            hr{
                margin-top: 20px;
                margin-bottom: 20px;
            }
            h5{
                font-weight: 400;
            }
            img{
                height: 40px;
            }
        }
    }
    // singleBlog 

    // dashboard 
    .dashboardNav{
        background-color: $drawer-color-dark;
        text-align: center;
        .navbar-brand{
            img{
                height: 50px;
            }
        }
        .navbar-nav{
            margin-top: 10px;
            margin-bottom: 10px;
            text-align: justify;
            padding: 10px 10px;
            background-color: $white-color;
            border-radius: 10px;
            li{
                font-size: x-large;
                text-transform: capitalize;
                color: $main-color;
                a{
                    color: $main-color !important;
                    font-weight: 600;
                }
                i{
                    margin-right: 10px;
                    color: $main-color;
                }
            }
        }
    }
    .dashboard{
        background-color: $light-color;
        .container-fluid{
            .leftt{
                padding: 0 !important;
            }
            .rightt{

            }
        }
    }
    .dashboardDrawer{
        color: white;
        background-color: $drawer-color;
        position: relative;
        .header{
            text-align: center;
            height: 8vh;
            // position: fixed;
            width: 100%;
            background-color: $drawer-color-dark;
            padding-top: 10px;
            img{
                max-width: 100%;
                width: auto;
                height: 50px;
            }
        }
        .content{
            height: 92vh;
            overflow: auto;
            -ms-overflow-style: none;  /* Internet Explorer 10+ */
            scrollbar-width: none;  /* Firefox */
            padding: 10px 30px;
            &::-webkit-scrollbar{
                // display: none;  /* Safari and Chrome */
                background-color: rgba(0, 0, 0, 0);
            }
            &::-webkit-scrollbar-thumb{
                background-color: $main-color-dark;
            }
        }
        &::-webkit-scrollbar{
            display: none;  /* Safari and Chrome */
        }
    }
    .dashboardContent{
        background-color: $light-color;
        overflow: auto;
        -ms-overflow-style: none;  /* Internet Explorer 10+ */
        scrollbar-width: none;  /* Firefox */
        height: 100vh;
        padding-top: 20px;
        padding-bottom: 20px;
        &::-webkit-scrollbar{
            display: none;  /* Safari and Chrome */
        }
    }
    .dashboardDrawerItems{
        margin: 20px auto;
        a{
            text-decoration: none;
            color: white;
            font-size: x-large;
            i{
                margin-right: 10px;
            }
        }
    }
    // dashboard 

    // user messages page 
    .userMessages{
        // padding-top: 50px;
        // padding-bottom: 50px;
    }
    // user messages page 

    .allMessages{
        // padding-top: 30px;
        // padding-bottom: 30px;
        .singleMessage{
            background-color: white;
            box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.5);
            padding: 30px;
            border-radius: 20px;
            margin-bottom: 30px;
            .readMoreBut{
                border: 1px solid $main-color;
                padding: 5px 10px;
                border-radius: 5px;
                font-size: large;
                margin-top: 10px;
                a{
                    text-decoration: none;
                    color: $main-color;
                    font-weight: 600;
                }
                &:hover{
                    background-color: $main-color-dark;
                    transform: scale(1.1);
                    a{
                        color: white;
                    }
                }
            }
        }
    }

    // .single message page 
    .singleMessagePage{
        // padding-top: 50px;
        // padding-bottom: 50px;
        .info{        
            color: $main-color;
            i{
                margin-right: 5px;
            }    
            a{
                color: $main-color;
                text-decoration: none;
                &:hover{
                    transform: scale(1.1);
                }
            }
        }
        button{
            color: white;
            background-color: $main-color;
            border: 0;
            padding: 5px 10px;
            border-radius: 3px;
            i{
                margin-right: 5px;
            }    
            a{
                color: white;
                text-decoration: none;
            }
            &:hover{
                background-color: $main-color-dark;
                transform: scale(1.1);
            }
        }
    }
    // .single message page 


    // teasers 
    .teaser-card{
        border:  2px solid #3c4b6486;
        background-color: white;
        padding: 20px;
        border-radius: 10px;
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.4);
        h6{
            color: $main-color;
        }
    }

    .singleAnalyticsTeaser{
        margin-top: 20px;
        margin-bottom: 20px;
    }
    .blogsAnalytics{
        p{
            margin-bottom: 5px !important;
        }
        .viewsList{
            height: 200px;
            overflow: auto;
            overflow-x: hidden;
            overflow: auto;
            -ms-overflow-style: none;  /* Internet Explorer 10+ */
            scrollbar-width: none;  /* Firefox */
            &::-webkit-scrollbar{
                display: none;  /* Safari and Chrome */
            }
        }
    }

    // teasers 

    .pagenotfound{
        background-size: cover;
        background-repeat: no-repeat;
        min-height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        h1{
            font-size: 100px;
            font-weight: 700;
        }
    }

}



// Media Queries 

@media only screen and (max-width: 992px) {

}
@media only screen and (max-width: 769px) {
}
@media only screen and (orientation : portrait) {
    body{
        overflow: auto !important;
    }
}
@media only screen and (max-width: 576px) {
    
    .login{
        background-color: $light-color;
        .loginLeft{
            width: 100% !important;
            display: none !important;
        }
        .loginRight{
            display: flex;
            align-items: center;
            justify-content: center;
            .form{
                width: 100% !important;
            }
        }
    }
}