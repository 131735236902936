@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap");
body {
  font-family: "Montserrat", sans-serif;
  background-color: #F1F1F1;
  overflow: hidden;
}
body input, body select, body textarea, body button:focus {
  outline: 0;
  outline: none;
}
body .mainSectionHeading {
  font-weight: 600;
  font-size: 30px;
  color: #3C4B64;
}
body .subSectionHeading {
  font-weight: 600;
  font-size: 45px;
}
body .separator {
  height: 2px;
  padding: 0 !important;
  background-color: #3C4B64;
  margin-top: 20px;
  margin-bottom: 20px;
}
body .applyBut {
  background-color: #3C4B64;
  color: white;
  border: 2px solid #3C4B64;
  padding: 5px 20px;
  font-size: larger;
  font-family: "Montserrat", sans-serif;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  -webkit-transition: all 0.5s ease-in;
  -moz-transition: all 0.5s ease-in;
  -o-transition: all 0.5s ease-in;
  border-radius: 2px;
  margin: 0 5px;
  margin-top: 10px;
}
body .applyBut:hover {
  transform: scale(1.05);
  background-color: white;
  color: #3C4B64;
}
body .mainButLight {
  background-color: white;
  border: 1px solid white;
  font-size: larger;
  font-weight: 600;
  color: #3C4B64;
  border-radius: 2px;
  padding: 5px 10px;
}
body .mainButLight:hover {
  background-color: #3C4B64;
  color: white;
}
body .mainButDark {
  background-color: #3C4B64;
  border: 1px solid #3C4B64;
  font-size: larger;
  font-weight: 600;
  color: white;
  border-radius: 3px;
  padding: 5px 10px;
}
body .mainButDark:hover {
  background-color: rgba(0, 0, 0, 0);
  color: #3C4B64;
}
body .mainButDarker {
  background-color: rgb(27, 27, 27);
  border: 1px solid rgb(27, 27, 27);
  font-size: larger;
  font-weight: 600;
  color: white;
  border-radius: 3px;
  padding: 5px 10px;
  font-family: "Montserrat", sans-serif;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  -webkit-transition: all 0.5s ease-in;
  -moz-transition: all 0.5s ease-in;
  -o-transition: all 0.5s ease-in;
}
body .mainButDarker:hover {
  transform: scale(1.1);
  background-color: rgb(39, 39, 39);
}
body .copyBut {
  background-color: #F1F1F1;
  color: rgb(44, 44, 44);
  border: 2px solid #F1F1F1;
  padding: 5px 20px;
  font-size: larger;
  font-family: "Montserrat", sans-serif;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  -webkit-transition: all 0.5s ease-in;
  -moz-transition: all 0.5s ease-in;
  -o-transition: all 0.5s ease-in;
  border-radius: 2px;
  margin: 0 5px;
  margin-top: 10px;
}
body .copyBut:hover {
  transform: scale(1.05);
  background-color: white;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
}
body .navLogoutBut {
  background-color: #fe2f2f;
  color: white;
  font-size: larger;
  font-weight: 600;
  border: 0;
  border-radius: 5px;
  padding: 10px 20px;
}
body .navLogoutBut i {
  margin-right: 10px;
}
body .navLogoutBut:hover {
  background-color: red;
}
body .loader {
  background-color: RGB(42, 88, 244);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  text-align: center;
}
body .loader h2 {
  color: white;
  font-weight: 700;
  text-transform: capitalize;
}
body .loader img {
  max-width: 100%;
}
body .login {
  background-color: #F1F1F1;
  color: black;
}
body .login .container-fluid .row .loginLeft {
  padding: 0 !important;
  background-size: auto 100%;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: white;
}
body .login .container-fluid .row .loginLeft h1 {
  font-weight: 900;
}
body .login .container-fluid .row .loginLeft h2 {
  font-weight: 800;
}
body .login .container-fluid .row .loginLeft .stats {
  background-color: rgba(27, 27, 27, 0) !important;
  border: 0 !important;
}
body .login .container-fluid .row .loginLeft .singleStat {
  background-color: rgba(27, 27, 27, 0.5) !important;
  padding-top: 20px;
  padding-bottom: 20px;
}
body .login .container-fluid .row .loginRight {
  text-align: center;
}
body .login .container-fluid .row .loginRight img {
  height: 200px;
  margin-bottom: 30px;
}
body .login .container-fluid .row .loginRight .form {
  text-align: left;
  width: 70%;
  margin: 0 auto;
  margin-top: 50px;
}
body .login .container-fluid .row .loginRight .form input, body .login .container-fluid .row .loginRight .form select {
  width: 100%;
  padding: 5px 10px;
  margin-bottom: 20px;
}
body .login .container-fluid .row .loginRight .form .passwordCol {
  position: relative;
}
body .login .container-fluid .row .loginRight .form .passwordCol span {
  position: absolute;
  right: 10px;
  top: 2px;
}
body .login .container-fluid .row .loginRight .form a {
  text-decoration: none;
  color: #3C4B64;
}
body .login .container-fluid .row .loginRight .form a:hover {
  color: #303C54;
}
body .login .container-fluid .row .loginRight .form button {
  width: 100%;
  padding: 10px 20px;
  margin-bottom: 10px;
  border: 0;
  background-color: #3C4B64;
  color: white;
  font-size: larger;
  font-weight: 600;
  font-family: "Montserrat", sans-serif;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  -webkit-transition: all 0.5s ease-in;
  -moz-transition: all 0.5s ease-in;
  -o-transition: all 0.5s ease-in;
}
body .login .container-fluid .row .loginRight .form button:hover {
  background-color: #073f52;
  transform: scale(1.05);
}
body .login .container-fluid .row .loginRight .form .createBut {
  width: 100%;
  padding: 10px 20px;
  margin-bottom: 10px;
  border: 0;
  background-color: white;
  border: 1px solid #3C4B64;
  color: #3C4B64;
  font-size: larger;
  font-weight: 600;
  font-family: "Montserrat", sans-serif;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  -webkit-transition: all 0.5s ease-in;
  -moz-transition: all 0.5s ease-in;
  -o-transition: all 0.5s ease-in;
}
body .login .container-fluid .row .loginRight .form .createBut:hover {
  background-color: #3C4B64;
  color: white;
}
body .createBlogPage {
  color: rgb(27, 27, 27);
}
body .createBlog {
  padding-top: 50px;
  padding-bottom: 50px;
}
body .createBlog .header .title input {
  width: 100%;
  padding: 5px 10px;
  background-color: rgba(0, 0, 0, 0);
  border: 1px solid rgb(196, 196, 196);
}
body .createBlog .header .title .desc {
  margin-top: 20px;
}
body .createBlog .header .imgUpload input {
  display: none;
}
body .createBlog .header .imgUpload .customImgUpload {
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: left;
}
body .createBlog .header .imgUpload .customImgUpload .imgPreview {
  width: 50px;
  height: 50px;
  position: relative;
  font-family: "Montserrat", sans-serif;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  -webkit-transition: all 0.5s ease-in;
  -moz-transition: all 0.5s ease-in;
  -o-transition: all 0.5s ease-in;
}
body .createBlog .header .imgUpload .customImgUpload .imgPreview .deleteBut {
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  color: red;
}
body .createBlog .header .imgUpload .customImgUpload .imgPreview img {
  width: 100%;
  height: 100%;
}
body .createBlog .header .imgUpload .customImgUpload .imgPreview:hover {
  cursor: pointer;
}
body .createBlog .header .imgUpload .customImgUpload .imgPreview:hover .deleteBut {
  background-color: rgba(255, 255, 255, 0.5);
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}
body .createBlog .header .imgUpload .customImgUpload button {
  background-color: #303C54;
  border: 0;
  color: white;
  padding: 5px 10px;
  border-radius: 3px;
}
body .createBlog .header .imgUpload .customImgUpload button:hover {
  background-color: #073f52;
}
body .createBlog .header .imgUpload .customImgUpload p {
  margin-top: 5px;
}
body .createBlog .quillBody {
  margin-top: 20px;
  margin-bottom: 20px;
}
body .createBlog .quillBody .quill .ql-editor {
  min-height: 300px;
}
body .createBlog .quillBody .mainButDark {
  margin-top: 20px;
}
body .createBlog .footer button {
  margin-top: 20px;
}
body .createBlog .footer .keywords input {
  width: 100%;
  padding: 5px 10px;
  background-color: rgba(0, 0, 0, 0);
  border: 1px solid rgb(196, 196, 196);
}
body .singleBlog .header img {
  width: 100%;
}
body .singleBlog .header h1 {
  margin-top: 20px;
  margin-bottom: 20px;
  font-weight: 800;
  color: #3C4B64;
}
body .singleBlog .body img {
  max-width: 100% !important;
}
body .singleBlog .author hr {
  margin-top: 20px;
  margin-bottom: 20px;
}
body .singleBlog .author h5 {
  font-weight: 400;
}
body .singleBlog .author img {
  height: 40px;
}
body .dashboardNav {
  background-color: #303C54;
  text-align: center;
}
body .dashboardNav .navbar-brand img {
  height: 50px;
}
body .dashboardNav .navbar-nav {
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: justify;
  padding: 10px 10px;
  background-color: white;
  border-radius: 10px;
}
body .dashboardNav .navbar-nav li {
  font-size: x-large;
  text-transform: capitalize;
  color: #3C4B64;
}
body .dashboardNav .navbar-nav li a {
  color: #3C4B64 !important;
  font-weight: 600;
}
body .dashboardNav .navbar-nav li i {
  margin-right: 10px;
  color: #3C4B64;
}
body .dashboard {
  background-color: #F1F1F1;
}
body .dashboard .container-fluid .leftt {
  padding: 0 !important;
}
body .dashboardDrawer {
  color: white;
  background-color: #3C4B64;
  position: relative;
}
body .dashboardDrawer .header {
  text-align: center;
  height: 8vh;
  width: 100%;
  background-color: #303C54;
  padding-top: 10px;
}
body .dashboardDrawer .header img {
  max-width: 100%;
  width: auto;
  height: 50px;
}
body .dashboardDrawer .content {
  height: 92vh;
  overflow: auto;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
  padding: 10px 30px;
}
body .dashboardDrawer .content::-webkit-scrollbar {
  background-color: rgba(0, 0, 0, 0);
}
body .dashboardDrawer .content::-webkit-scrollbar-thumb {
  background-color: #303C54;
}
body .dashboardDrawer::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}
body .dashboardContent {
  background-color: #F1F1F1;
  overflow: auto;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
  height: 100vh;
  padding-top: 20px;
  padding-bottom: 20px;
}
body .dashboardContent::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}
body .dashboardDrawerItems {
  margin: 20px auto;
}
body .dashboardDrawerItems a {
  text-decoration: none;
  color: white;
  font-size: x-large;
}
body .dashboardDrawerItems a i {
  margin-right: 10px;
}
body .allMessages .singleMessage {
  background-color: white;
  box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.5);
  padding: 30px;
  border-radius: 20px;
  margin-bottom: 30px;
}
body .allMessages .singleMessage .readMoreBut {
  border: 1px solid #3C4B64;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: large;
  margin-top: 10px;
}
body .allMessages .singleMessage .readMoreBut a {
  text-decoration: none;
  color: #3C4B64;
  font-weight: 600;
}
body .allMessages .singleMessage .readMoreBut:hover {
  background-color: #303C54;
  transform: scale(1.1);
}
body .allMessages .singleMessage .readMoreBut:hover a {
  color: white;
}
body .singleMessagePage .info {
  color: #3C4B64;
}
body .singleMessagePage .info i {
  margin-right: 5px;
}
body .singleMessagePage .info a {
  color: #3C4B64;
  text-decoration: none;
}
body .singleMessagePage .info a:hover {
  transform: scale(1.1);
}
body .singleMessagePage button {
  color: white;
  background-color: #3C4B64;
  border: 0;
  padding: 5px 10px;
  border-radius: 3px;
}
body .singleMessagePage button i {
  margin-right: 5px;
}
body .singleMessagePage button a {
  color: white;
  text-decoration: none;
}
body .singleMessagePage button:hover {
  background-color: #303C54;
  transform: scale(1.1);
}
body .teaser-card {
  border: 2px solid rgba(60, 75, 100, 0.5254901961);
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.4);
}
body .teaser-card h6 {
  color: #3C4B64;
}
body .singleAnalyticsTeaser {
  margin-top: 20px;
  margin-bottom: 20px;
}
body .blogsAnalytics p {
  margin-bottom: 5px !important;
}
body .blogsAnalytics .viewsList {
  height: 200px;
  overflow: auto;
  overflow-x: hidden;
  overflow: auto;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}
body .blogsAnalytics .viewsList::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}
body .pagenotfound {
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
body .pagenotfound h1 {
  font-size: 100px;
  font-weight: 700;
}

@media only screen and (orientation: portrait) {
  body {
    overflow: auto !important;
  }
}
@media only screen and (max-width: 576px) {
  .login {
    background-color: #F1F1F1;
  }
  .login .loginLeft {
    width: 100% !important;
    display: none !important;
  }
  .login .loginRight {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .login .loginRight .form {
    width: 100% !important;
  }
}/*# sourceMappingURL=index.css.map */